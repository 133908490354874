import React, { useState } from "react"
import LayoutLogged from "../../components/layout/logged"
import Container from '@material-ui/core/Container';
import { useForm } from "react-hook-form";
import AppForm from "../../components/form/app-form";
import OrganizationForm from "../../components/pages/organization-form";
import { ifNotHaveAccessRedirect } from "../../services/auth";

export default function OrganizationCreate() {
  const { control, handleSubmit, formState: { errors } } = useForm();
  const [ errorsApi, setErrorsApi ] = useState(undefined);

  if (ifNotHaveAccessRedirect('organization.write')) return <></>;

  return (
    <LayoutLogged title="Criar uma organização">
      <Container>
        <AppForm
          api="/organization"
          linksPath="/organization"
          handleSubmit={handleSubmit}
          onErrors={setErrorsApi}
          control
        >
          <OrganizationForm
            control={control}
            errorsForm={errors}
            errorsApi={errorsApi}
          />
        </AppForm>
      </Container>
    </LayoutLogged>
  );
}